import React from "react";
import "./Loading.scss";

function Loading() {
  return (
    <div className="loading__container">
      <div className="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loading;
